@font-face {
  font-family: 'metropolisbold';
  src: url('fonts/metropolis-bold-webfont.woff2') format('woff2'),
    url('fonts/metropolis-bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'metropolislight';
  src: url('fonts/metropolis-light-webfont.woff2') format('woff2'),
    url('fonts/metropolis-light-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'metropolismedium';
  src: url('fonts/metropolis-medium-webfont.woff2') format('woff2'),
    url('fonts/metropolis-medium-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'metropolisregular';
  src: url('fonts/metropolis-regular-webfont.woff2') format('woff2'),
    url('fonts/metropolis-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'metropolissemi_bold';
  src: url('fonts/metropolis-semibold-webfont.woff2') format('woff2'),
    url('fonts/metropolis-semibold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'metropolisthin';
  src: url('fonts/metropolis-thin-webfont.woff2') format('woff2'),
    url('fonts/metropolis-thin-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
